.cursor__pointer {
  cursor: pointer;
}
@font-face {
  font-family: 'Arabic';
  src: url('./Assets/Fonts/Arabic.woff');
}

*{
  font-family: 'Arabic', sans-serif !important
}

.grey_text {
  font-size:14px;
  margin:0;
  padding: 0;
  color: #57585A;
}

.lg_text {
  font-size: 28px;
}

.grey-backgroud {
  background-color: #F2F2F7;
}

.basic_height {
  line-height: 1.5;
}

/*.textField fieldset {*/
/*  border-radius: 15px;*/
/*}*/

button {
  max-width: 360px;
}

/*.textFieldGroup fieldset[dir="rtl"] {*/
/*  border-top-left-radius: 0px;*/
/*  border-bottom-left-radius: 0px;*/
/*}*/
/*.textFieldGroup fieldset[dir="ltr"] {*/
/*  border-top-right-radius: 0px;*/
/*  border-bottom-right-radius: 0px;*/
/*}*/

body {
  background-color: #F2F2F7;
  margin-bottom: 150px;
}

html, body, #root, #root>div {
  height: 100%;
  overflow-x: hidden;
}

#root {
  background-color: #F2F2F7;
}

@media (max-width: 576px){
  .sm-pad-zero {
    padding:0 !important;
  }
}

@media (min-width: 576px){
  .sm-pad-zero {
    padding:0 !important;
  }
}