.pad_text {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.7;
}

.item_seperator {
    border: #F2F2F7 1px solid;
    margin: 0px;
    opacity: .75;
}


.border {
    border-radius: 15px;
}

.row{
    flex-direction: column;
}
.padding_0{
    padding: 0 !important;
}

.cardId_container {
    border: 3px dashed #dddddd;
    background-color: white;
    border-radius: 8px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 127px;
    padding: 5px;
    gap: 3px;
    align-items: center;

}

.gray_text {
    font-size: 20px !important;
    color: #57585a !important;
}

.cursor {
    cursor: pointer;
}

.black_text {
    margin-top: 2px !important;
    color: black;

}

.copy_style {
    font-size: 15px;
    color: #0179ff !important;
}


.bold_text {
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.centered_text {
    display: flex;
    align-items: center;
}


.total_font_size {
    font-size: 18px;
}

.normal_font_size {
    font-size: 16px;
}
.red_text{
    color: red;
}

