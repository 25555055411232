.pad_text {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.7;
}

.item_seperator {
    border: #F2F2F7 1px solid;
    margin: 0px;
    opacity: .75;
}


.border {
    border-radius: 15px;
}
.no_pad {
    padding: 0;
    margin-top: 0px;
}
.font_size_sm{
    font-size: 16px;

}
.error_btn{
    position: relative;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    transition: 0.2s all;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    background-color: #0179ff;
    height: 50px;
    width: 100%;

}
.error_btn:hover {
    box-shadow: 3px 2px 22px 1px rgba(98, 97, 97, 0.24);
}
.error_btn:active {
    background-color: #2b89f3;
}
.hyperlink{
    text-decoration: none;
    color: #2b89f3;
    font-weight: bold;
}
.iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 8px;
    color: #0179ff;
}

.cardId_container {
    border: 3px dashed #dddddd;
    background-color: white;
    border-radius: 8px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 127px;
    padding: 5px;

}

.gray_text {
    font-size: 20px !important;
    color: #57585a !important;
}

.cursor {
    cursor: pointer;
}

.black_text {
    margin-top: 2px !important;
    color: black;

}

.copy_style {
    color: #0179ff !important;
}


.bold_text {
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.centered_text {
    display: flex;
    align-items: center;
}


.total_font_size {
    font-size: 20px;
}

.normal_font_size {
    font-size: 16px;
}

