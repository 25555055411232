.item_container:first-child {
    border-radius: 15px;
}

.item_container:last-child {
    border-radius: 15px;
}

.item_wrapper {
    padding: 5px;
    /*background-color: white;*/
    /*border-radius: 15px;*/
}

.item_row {
    display: flex;
    align-items: center;
}

.item_col {
    width: 30px !important;
}

.item_img {
    height: 30px;
}

.item_margin {
    margin: 0px 12px 0px 12px;
}

.size_text {
    font-size: 16px;
}

.size_icon {
    height: 27px;
    padding-left: 15px;
}

.trash_btn {
    display: block !important;
    min-width: 15px;
}


.size_trash_icon {
    width: 12px;
    height: 15px;
    display: block;
    float: left;
}


.item_seperator {
    margin: 10px 15px;
    color: lightgray;

}
