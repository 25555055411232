.row {
    justify-content: center;
    display: flex;
    align-items: center;
}

.container {
    vertical-align: middle;
    font-size: 26px;
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1200px;
    margin: auto auto;
}

.payment_info_container_style {
    vertical-align: middle;
    font-size: 26px;
    padding: 10px 15px 10px 15px;
    max-width: 1200px;
    margin: auto;
    background-color: #f8f8f8;
    border-radius: 10px;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 8px;
    color: #0179ff;
}


.huge_icon {
    font-size: 150px;
}
.font_size_sm{
    font-size: 16px;

}

.font_size_md {
    font-size: 20px;
}
.text_align_center{
    text-align: center !important;
}
.bold{
    font-weight: bold !important;
}

.color_success {
    color: #00AB89;
}

.color_fail {
    color: #B00020;
}

.no_pad {
    padding: 0;
    margin-top: 0px;
}

.row_summary {
    justify-content: center;
    display: flex;
    align-items: center;
}

.summary_info {
    background: #cacacb;
    padding: 15px;
}


.btn {
    position: relative;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    transition: 0.2s all;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    background-color: #0179ff;
    height: 50px;

}
.btn:hover {
    box-shadow: 3px 2px 22px 1px rgba(98, 97, 97, 0.24);
}
.btn:active {
    background-color: #2b89f3;
}
.error_btn{
    position: relative;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    transition: 0.2s all;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    background-color: #0179ff;
    height: 50px;
    width: 100%;

}
.error_btn:hover {
    box-shadow: 3px 2px 22px 1px rgba(98, 97, 97, 0.24);
}
.error_btn:active {
    background-color: #2b89f3;
}
.hyperlink{
    text-decoration: none;
    color: #2b89f3;
    font-weight: bold;
}
.button_container{
    padding:10px 0px ;
    margin:0 !important;
    width: 100% !important;
}
.buttonTitle{
    display: inline-block !important;
    margin-top: 5px;
    font-size: 20px;

}
