.shortSummery_container {
    padding: 15px 0px 15px 0px;
    background-color: white;
    margin: 20px 0px 20px 0px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.shortSummery_row {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.shortSummery_col {
    display: flex;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}


.shortSummery_font_header {
    font-size: 20px;
    font-weight: bold;
}

.shortSummery_font_header_lg {
    font-size: 20px;
    font-weight: bold;
}
.container {

}
.wrapper {
    padding-bottom: 8px;
    /*box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);*/
    /*background-color: white;*/
}
.title {
    font-size: 24px;
    font-weight: bold;
    padding: 15px;
}

.text_box {
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.total_text_box{
    padding-right: 15px;
    padding-left: 15px;

}
.separator{
    margin: 15px;
    color: #88898c;
}