
.modal_container{
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;

}
.confirmationButton{
    background-color:  #0179FF !important; ;
    color: white !important;
    height: 45px !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

