.navbar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.container {
    vertical-align: middle;
    font-size: 26px;
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1200px;
    margin:auto;
}

.pad {
    padding-left: 15px;
    padding-right: 15px;
}

.navbar_back {
    display: flex;
    width: 20px;
}

.navbar_help {
    color: #0179FF;
    font-size: 20px;
}
.cursor{
    cursor: pointer;
}