.wrapper {
    bottom: -242.5px;
    left: 0;
    right:0;
    position: fixed;
    z-index: 99999;
}


.close_btn {
    font-size: 30px;
}

.close_btn:hover {
    color: dimgray;
    cursor: pointer;
}

.text_sizing {
    font-size: 18px;
}
.button {
    font-size: 22px !important;
    border-radius: 10px !important;
    background-color: #0179FF !important;
    font-weight: bold !important;
}

.color_white {
    background-color: white;
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.max_pad {
    padding: 20px 25px 20px 25px;
}

.title_text {
    font-size: 20px;
    font-weight: bold;
}
.alignCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}
.padding0{
    padding: 0 !important;
}