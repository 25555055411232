.list_container {
    margin: 30px 8px;

}

/*@media (max-width: 567px){*/
/*    .list_container {*/
/*        margin-left: 0;*/
/*        margin-right: 0;*/
/*    }*/
/*}*/

.blocked_background {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    bottom: 0;
    opacity: 0.4;
    z-index: 9990;
    display:none;
}
.parent_container{
    background-color: white;


}
.separator{
    margin: 15px;
    color: lightgray;
}
.item_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.liner_font {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 14px;
}

.liner_font_other {
    padding: 0px 15px 0px 15px;
    font-size: 14px;
}

.liner_link {
    text-decoration: none;
    color: #0179FF;
}

.font_size_sm {
    font-size: 14px;
}

.item_wrapper {
    margin: 15px 8px;
    padding:10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

}

@media (max-width: 500px){
    .item_wrapper {
        margin: 15px 0px;
        padding:10px 6px;
    }
}



.size_text {
    font-size: 18px;
}

.item_wrapper:hover {
    background-color: #F2F2F7;
}

.item_wrapper {
    cursor: pointer;
}

.pad_text {
    line-height: 2;
    float:left;
}

.pad_text_right {
    float:right;
    margin-left: 2px;
    margin-right: 2px;
    line-height: 2;
    height: 100%;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2px;
    background-color: #FFF;
    border-radius:5px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
}


.item_img {
    display: flex;
    justify-content: space-between;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
}

.blue_color {
    color: #0179FF;
}

.item_sub_img {
    height: inherit;
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
}

.holder_img {
    display: block;
    height: 100%;
}
.footer_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.lines_container{
    margin:20px 20px;
    border: 1px dashed lightgray;
    border-radius: 10px;
    padding: 10px;
}
.padding_0{
    margin: 10px;
    padding: 0 !important;
}
