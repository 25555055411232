.container {
    margin-bottom: 5px;
}

.wrapper {
    /*background-color: white;*/
    padding-bottom: 10px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    padding: 15px;
}

.text_box_rtl {
    margin: auto;
    padding-right: 15px;

}

.text_box_ltr {
    margin: auto;
    padding-left: 15px;

}

.button_container {
    width: inherit;
}

.width {
    width: inherit;
    display: flex;
    justify-content: center;
}

.margin {
    width: 100%;
    padding-left: 15px;
    height: 100%;
}

.button_margin_ltr {
    width: 100%;
    padding-right: 15px;
    height: 56px;
}

.button_margin_rtl {
    width: 100%;
    padding-left: 15px;
    height: 56px;
}


.skeleton_style {
    width: 95% !important;
    height: 100px !important;
    border-radius: 10px !important;
    margin: 10px !important;

}

.border_radius fieldset {
    border-radius: 6px !important;
    border: 1px solid #f0efef;

}
.input_col{
    padding: 5px 15px;
}

.input_col input {
    padding: 12px;
}

.coupon_apply {
    cursor: pointer !important;
    padding: 10px;
    color: #0179FF !important;

}
.input_col input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000000;
    opacity: 0.8; /* Firefox */
}


